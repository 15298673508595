import axios from 'axios'
import baseUrl from './baseUrl'
const errors = err => {
  console.error(err.response ? err.response.data.stack : err.stack)
  return Promise.reject(err.response ? err.response.data.message:'Houve uma falha na comunição, verifique sua internet e tente novamente')
}
const headers = {token: localStorage.getItem('token')}
const repository = axios.create({ baseURL: baseUrl.tnAdmin, headers })
const prepareFile = (file) => {
  const fd = new FormData()
  fd.append('video', file)
  return fd
}
export const sendVideoAds = (file) => repository.post('/repository/video/540/960', prepareFile(file)).then(({data}) => data)
.catch(errors)

export const sendImageAds = (file) => repository.post('/repository/image/1080/1920', prepareFile(file)).then(({data}) => data)
.catch(errors)

export const sendImageNews = (file) => repository.post('/repository/image/1080/1000', prepareFile(file)).then(({data}) => data)
.catch(errors)

export const sendImageLogo = (file) => repository.post('/repository/image/150/150', prepareFile(file)).then(({data}) => data)
.catch(errors)

export const getFile = (ticket) => repository.get('/repository/get-file/'+ticket).then(({data}) => data)
.catch(errors)